<template>
  <v-container>

    <v-row>
      <v-col cols="12">

        <v-row class="mb-3">
          <v-col>
            <v-btn to="/admin/registrationstatus" color="secondary" small>
              <v-icon class="mr-2">mdi-arrow-left</v-icon>
              Volver
            </v-btn>
          </v-col>
        </v-row>

        <v-card>
          <v-card-title class="pb-0">

            <v-container>

              <v-row>
                <v-col class="mb-2 py-0">
                  <p class="text-h5 mb-0">
                    <v-avatar color="orange" size="38"><v-icon dark>mdi-draw</v-icon></v-avatar>
                    Inscripciones de Talleres
                  </p>
                </v-col>
              </v-row>

              <v-row class="mt-2 py-0">
                <v-col cols="8">
                  <v-text-field v-model="search" label="Buscar" prepend-inner-icon="mdi-magnify" single-line hide-details clearable filled rounded dense class="searchinput" />                </v-col>
                <v-col cols="4">
                  <v-btn block small color="success" class="mt-1" to="/admin/registrations/create" >
                    Nuevo Proc. Inscripción
                  </v-btn>
                </v-col>

              </v-row>

            </v-container>
            
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="registrations"
            :search="search"
            :loading="isLoading"
          >

            <template v-slot:item.startDate="{ item }">
              {{getDDMMYYYY(item.startDate)}}
            </template>

            <template v-slot:item.endDate="{ item }">
              {{getDDMMYYYY(item.endDate)}}
            </template>

            <template v-slot:item.courses="{ item }">
              {{(item.courses) ? item.courses.length : 0}}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="goToRegistration(item._id)">mdi-pencil</v-icon>
              <v-icon small class="mr-2" @click="openDeleteDialog(item._id)">mdi-close</v-icon>
            </template>

          </v-data-table>

        </v-card>

      </v-col>
    </v-row>

    <!-- CRUD Dialogs -->
    <DeleteItemDialog v-model="deleteDialog" :deleteURL="deleteURL" @deleted="loadRegistrations()" />

  </v-container>
</template>

<script>
import DeleteItemDialog from '@/components/admin/DeleteItemDialog.vue'
import { getDDMMYYYY } from '@/libs/dateHelpers'

export default {
  name: `AdminRegistration`,
  components: {
    DeleteItemDialog,
  },
  data: () => ({
    //state
    search: '',
    isLoading: true,
    deleteDialog: false,
    deleteURL: '',
    //data
    headers: [
      { text: 'Nombre', value: 'name' },
      { text: 'Fecha Inicio', value: 'startDate' },
      { text: 'Fecha Término', value: 'endDate' },
      { text: '# Talleres', value: 'courses' },
      { text: 'Acciones', value: 'actions' },
    ],
    registrations: [],
  }),
  methods: {
    async loadRegistrations() {
      try {
      
        this.isLoading = true
      
        const resp = await this.axios.get(`${process.env.VUE_APP_APIURL}/registration/all`)

        if(!resp.data.data) {
          this.registrations = []
          this.isLoading = false
          return
        }

        this.registrations = resp.data.data.map(regs => {
          return { 
            ...regs, 
            startDate: new Date(regs.startDate.replace('Z', '')),
            endDate: new Date(regs.endDate.replace('Z', '')),
          }
        })
      
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    goToRegistration(regId) {
      this.$router.push(`/admin/registrations/edit/${regId}`)
    },
    openDeleteDialog(id) {
      this.deleteURL = `${process.env.VUE_APP_APIURL}/registration/id/${id}`
      this.deleteDialog = true
    },
  },
  mounted() {
    this.loadRegistrations()
  },
  beforeCreate() { // make libs available in template
    this.getDDMMYYYY = getDDMMYYYY
  }
}
</script>